.App {
  text-align: center;
  background-color: #f2f2f2;
  display:flex;
  flex-direction: column;
  min-height: 100%;
  font-family: 'Public Sans', sans-serif;
  color: rgb(77, 75, 75);
  overflow: hidden;
}

h1 {
  color: #1F4327;
}

h2 {
  color: #1F4327;
}