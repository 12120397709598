* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  #root {
    height: 100%
  }
  
  html, body {
    height: 100%;
  }

  .app-body {
    flex: 1 0 auto;
  }
  
  .footer {
    flex-shrink: 0;
  }