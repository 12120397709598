.slideContainer {
    width: 100%;
    height: 100%;
    
}

.slideFlex {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    gap:1rem;
}


.slideContainer img {
    width: 75%;
    border-radius: 1rem;
}

.slideContainer a {
    text-decoration: none;
}

.slideContainer .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
}