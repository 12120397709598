.footer-body {
    color: #F2f2f2;
    background-color: #1F4327;
    height: 200px;
    padding: 2rem;
}

.footer-body h3 {
    margin-bottom: 1rem;
    font-weight: 400;
}


.footer-container {
    display: flex;
    flex-direction: row;
    width: 80%;
    gap: 2rem;
    margin: 0;
    padding: 0;
}

.footer-pages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-pages a {
    text-decoration: none;
    color: #F2F2F2;
    margin-bottom: 0.5rem;
}

.footer-pages a:hover {
    cursor: pointer;
}

.footer-items {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.footer-socials h3 {
    text-align: left;
}