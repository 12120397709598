.slideshow {
    margin: 0 auto;
    overflow: visible;
    max-width: 100%;
    margin-bottom: 2rem;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
}

.slide {
    display: inline-block;

    /* height: 45vh; */
    width: 100%;
    border-radius: 1rem;
    margin-bottom: 1rem;
}

/* Buttons */

.slideshowDots {
    text-align: center;
}

.slideshowDot {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    cursor: pointer;
    margin: 0.5rem 0.25rem 0.25rem;
    background-color: #c4c4c4;
}

.active {
    background-color: #1F4327;
}