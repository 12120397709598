.app-Container {
    max-width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
    gap: 2rem;
    padding: 3rem;
}

@media screen and (max-width: 800px) {
    .app-Container {
        max-width: 100vw;
        padding: 1rem;
    }
}